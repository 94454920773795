import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { Component } from 'jsx-engine';
import _set from 'lodash/set';

export enum DROP_PLACE {
    BEFORE = 'before',
    AFTER = 'after',
    IN = 'in',
}

export const componentId = () => Math.random().toString();

const initialState: Component = {
    id: componentId(),
    type: 'div',
    props: {
        style: {
            width: '100%',
            height: '100%',
            display: 'flex',
        },
    },
    children: [],
};

const structureSlice = createSlice({
    name: 'structure',
    initialState,
    reducers: {
        addComponent: (
            state,
            action: PayloadAction<{
                targetId: string;
                component: {
                    id?: string;
                    type: string;
                    props?: Component['props'];
                    children?: Component['children'];
                };
                place: DROP_PLACE;
                wrap?: boolean;
            }>
        ) => {
            const targetId = action.payload.targetId;
            const place = action.payload.place;
            const target = getComponentById(state, targetId) ?? state;
            const newComponent = {
                ...action.payload.component,
                id: action.payload.component.id ?? Math.random().toString(),
            };

            if (target) {
                if (action.payload.wrap) {
                    const targetCopy = { ...target };

                    target.id = newComponent.id;
                    target.props = newComponent.props;
                    target.type = newComponent.type;
                    target.children = [targetCopy];
                } else if (
                    place === DROP_PLACE.BEFORE ||
                    place === DROP_PLACE.AFTER
                ) {
                    const parent = getParentComponent(state, targetId);
                    if (parent) {
                        if (Array.isArray(parent.children)) {
                            const targetIndex = parent.children.findIndex(
                                (child) => child.id === targetId
                            );
                            if (place === DROP_PLACE.BEFORE) {
                                parent.children.splice(
                                    targetIndex,
                                    0,
                                    newComponent
                                );
                            } else if (place === DROP_PLACE.AFTER) {
                                parent.children.splice(
                                    targetIndex + 1,
                                    0,
                                    newComponent
                                );
                            }
                        } else if (typeof parent.children === 'string') {
                            parent.children =
                                place === DROP_PLACE.BEFORE
                                    ? [
                                          newComponent,
                                          stringToComponent(parent.children),
                                      ]
                                    : [
                                          stringToComponent(parent.children),
                                          newComponent,
                                      ];
                        }
                    }
                } else {
                    if (Array.isArray(target.children)) {
                        target.children.push(newComponent);
                    } else if (!target.children) {
                        target.children = [newComponent];
                    } else if (typeof target.children === 'string') {
                        target.children = [
                            stringToComponent(target.children),
                            newComponent,
                        ];
                    }
                }
            }
        },
        removeComponent: (
            state,
            action: PayloadAction<{
                id: string;
            }>
        ) => {
            const idToRemove = action.payload.id;
            if (state.id === idToRemove) {
                return initialState;
            }
            const parent = getParentComponent(state, action.payload.id);
            if (parent && Array.isArray(parent.children)) {
                parent.children = parent.children.filter(
                    (child) => child.id !== idToRemove
                );
            }
        },
        moveComponent: (
            state,
            action: PayloadAction<{
                idToMove: string;
                targetId: string;
                place: DROP_PLACE;
            }>
        ) => {
            const { idToMove, targetId, place } = action.payload;
            const target = getComponentById(state, targetId);
            const targetParent = getParentComponent(state, targetId);
            const component = getComponentById(state, idToMove);
            const componentParent = getParentComponent(state, idToMove);

            if (!target || !component || !componentParent) {
                return;
            }

            // first remove component
            if (Array.isArray(componentParent.children)) {
                componentParent.children = componentParent.children.filter(
                    (child) => child.id !== idToMove
                );
            } else {
                componentParent.children = [];
            }

            // then place it to location
            if (place === DROP_PLACE.IN) {
                target.children = Array.isArray(target.children)
                    ? target.children
                    : target.children
                    ? [
                          typeof target.children === 'string'
                              ? stringToComponent(target.children)
                              : target.children,
                      ]
                    : [];
                target.children.push(component);
            } else if (
                place === DROP_PLACE.BEFORE ||
                place === DROP_PLACE.AFTER
            ) {
                if (!targetParent) {
                    // if there is no parent, component was moved at the top
                    let newRootChildren = [{ ...target }];
                    if (Array.isArray(component.children)) {
                        newRootChildren = newRootChildren.concat(
                            component.children
                        );
                    } else if (typeof component.children === 'string') {
                        newRootChildren.push(
                            stringToComponent(component.children)
                        );
                    }
                    state.id = component.id;
                    state.props = { ...component.props };
                    state.type = component.type;
                    state.children = newRootChildren;
                } else if (Array.isArray(targetParent.children)) {
                    const targetIndex = targetParent.children.findIndex(
                        (child) => child.id === targetId
                    );
                    targetParent.children.splice(
                        place === DROP_PLACE.BEFORE
                            ? targetIndex
                            : targetIndex + 1,
                        0,
                        component
                    );
                }
            }
        },
        updateComponent: (
            state,
            action: PayloadAction<{
                id: string;
                props?: Component['props'];
                children?: string;
                title?: string;
            }>
        ) => {
            const comp = getComponentById(state, action.payload.id);
            if (comp && action.payload.props) {
                comp.props = {
                    ...comp.props,
                    ...action.payload.props,
                };
            }
            if (comp && action.payload.children !== undefined) {
                comp.children = action.payload.children;
            }
            if (comp && 'title' in action.payload) {
                comp.title = action.payload.title;
            }
        },
        updateComponentByPath: (
            state,
            action: PayloadAction<{
                id: string;
                path: string;
                value: any;
            }>
        ) => {
            const comp = getComponentById(state, action.payload.id);
            if (comp) {
                _set(
                    comp,
                    ['children', 'title'].includes(action.payload.path)
                        ? action.payload.path
                        : `props.${action.payload.path}`,
                    action.payload.value
                );
            }
        },
        replaceStructure: (_, action: PayloadAction<Component | undefined>) => {
            return action.payload ?? { ...initialState };
        },
    },
});

export function getComponentById(
    component: Component,
    id: string
): Component | undefined {
    if (component.id === id) {
        return component;
    }

    let found: Component | undefined;
    if (component.children === undefined) {
        return;
    }
    for (const s of component.children) {
        if (typeof s !== 'string') {
            found = getComponentById(s, id);
        }
        if (found) {
            return found;
        }
    }
}

export function getComponentsByType(
    component: Component,
    type: string
): Component[] {
    const components: Component[] = [];
    function traverseStructure(structure: Component) {
        if (structure.type === type) {
            components.push(structure);
        }
        if (structure.children === undefined) {
            return;
        }
        for (const child of structure.children) {
            if (typeof child !== 'string') {
                traverseStructure(child);
            }
        }
    }
    traverseStructure(component);
    return components;
}

export function isParentOf(component: Component, componentId: string) {
    if (component.id === componentId) {
        return false;
    }
    function tranverseStructure(
        structure: Component | Component[] | string | undefined
    ): boolean {
        if (Array.isArray(structure)) {
            return structure.some(tranverseStructure);
        } else if (typeof structure === 'object') {
            if (structure && structure.id === componentId) {
                return true;
            }
            return tranverseStructure(structure.children);
        }
        return false;
    }

    return tranverseStructure(component);
}

function getParentComponent(
    component: Component,
    id: string
): Component | undefined {
    if (Array.isArray(component.children)) {
        if (component.children.some((it) => it.id === id)) {
            return component;
        }
    }

    let found: Component | undefined;
    if (component.children === undefined) {
        return;
    }
    for (const s of component.children) {
        if (typeof s !== 'string') {
            found = getParentComponent(s, id);
        }
        if (found) {
            return found;
        }
    }
}

function stringToComponent(text: string) {
    return {
        id: componentId(),
        type: 'span',
        children: text,
    };
}

export function deepCopy(component: Component): Component {
    function traverseAndGenerateNewId(component: Component | string) {
        if (typeof component === 'string') {
            return component;
        }
        component.id = componentId();
        if (Array.isArray(component.children)) {
            component.children = component.children.map(deepCopy);
        } else if (
            component.children &&
            typeof component.children === 'object'
        ) {
            (component.children as Component).id = componentId();
        }
        return component;
    }
    return traverseAndGenerateNewId(component) as Component;
}

export const {
    updateComponent,
    updateComponentByPath,
    addComponent,
    moveComponent,
    removeComponent,
    replaceStructure,
} = structureSlice.actions;
export default structureSlice;
