import { ReactKeycloakProvider } from '@react-keycloak/web';
import Keycloak from 'keycloak-js';
import { SnackbarProvider } from 'notistack';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { MemoryRouter } from 'react-router-dom';
import 'whatwg-fetch';
import { AppBar } from '/@/components/app-bar';
import { store } from '/@/store';
import { ThemeProvider } from '/@/theme/ThemeProvider';

const keycloak = new Keycloak('/keycloak.json');
const App = React.lazy(() => import('./App'));

ReactDOM.render(
    <ReactKeycloakProvider
        authClient={keycloak}
        initOptions={{ onLoad: 'login-required' }}
    >
        <React.StrictMode>
            <Provider store={store}>
                <ThemeProvider>
                    <SnackbarProvider>
                        <AppBar />
                        <MemoryRouter>
                            <Suspense fallback={null}>
                                <App />
                            </Suspense>
                        </MemoryRouter>
                    </SnackbarProvider>
                </ThemeProvider>
            </Provider>
        </React.StrictMode>
    </ReactKeycloakProvider>,
    document.getElementById('root')
);
