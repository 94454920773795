import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { Document } from 'jsx-engine';
import _set from 'lodash/set';

const initialState: Document = {
    dimensions: {
        width: '4in',
        height: '3in',
    },
};

const documentSlice = createSlice({
    name: 'document',
    initialState,
    reducers: {
        updateDimensions: (
            state,
            action: PayloadAction<Partial<Document['dimensions']>>
        ) => {
            state.dimensions = {
                ...state.dimensions,
                ...action.payload,
            };
        },
        updateDocumentByPath: (
            state,
            action: PayloadAction<{
                path: string;
                value: any;
            }>
        ) => {
            _set(state, action.payload.path, action.payload.value);
        },
        replaceDocument: (_, action: PayloadAction<Document>) => {
            return action.payload;
        },
    },
});

export const { updateDimensions, replaceDocument, updateDocumentByPath } =
    documentSlice.actions;
export default documentSlice;
