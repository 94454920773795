import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { ITemplateWithType } from '/@/types/template-service-aggregations';

export type EditingTemplate = Omit<
    ITemplateWithType,
    'template' | 'templateType'
> & { templateTypeName: string };
interface EditorState {
    selectedComponentId?: string;
    hiddenComponents?: string[];
    zoom: number;
    editingTemplate?: EditingTemplate;
    editorComponentsProps: Record<string, object>;
    editorComponentsErrors: Record<string, string[]>;
}

const initialState: EditorState = {
    zoom: 1,
    hiddenComponents: [],
    editorComponentsProps: {},
    editorComponentsErrors: {},
};

const editorSlice = createSlice({
    name: 'editor',
    initialState,
    reducers: {
        selectComponent: (state, action: PayloadAction<string | undefined>) => {
            state.selectedComponentId = action.payload;
        },
        performZoom: (state, action: PayloadAction<number>) => {
            state.zoom = action.payload;
        },
        setEditingTemplate: (
            state,
            action: PayloadAction<ITemplateWithType>
        ) => {
            const payload = action.payload;
            state.editingTemplate = {
                companyProfileId: payload.companyProfileId,
                companyProfile: payload.companyProfile,
                templateName: payload.templateName,
                description: payload.description,
                templateId: payload.templateId,
                templateTypeId: payload.templateTypeId,
                templateTypeName: payload.templateType.templateTypeName,
                isDefault: payload.isDefault,
            };
        },
        setTemplateId: (state, action: PayloadAction<string>) => {
            if (state.editingTemplate) {
                state.editingTemplate = {
                    ...state.editingTemplate,
                    templateId: action.payload,
                };
            }
        },
        resetEditorState: () => {
            return initialState;
        },
        setEditorComponentProps: (
            state,
            action: PayloadAction<{ componentId: string; props: any }>
        ) => {
            const { componentId, props } = action.payload;
            if (!state.editorComponentsProps) {
                state.editorComponentsProps = {};
            }
            state.editorComponentsProps[componentId] = {
                ...state.editorComponentsProps[componentId],
                ...props,
            };
        },
        setEditorComponentError: (
            state,
            action: PayloadAction<{ componentId: string; error: string }>
        ) => {
            const { componentId, error } = action.payload;
            if (!Array.isArray(state.editorComponentsErrors[componentId])) {
                state.editorComponentsErrors[componentId] = [error];
            } else {
                state.editorComponentsErrors[componentId].push(error);
            }
        },
        clearEditorComponentErrors: (
            state,
            action: PayloadAction<{ componentId: string }>
        ) => {
            const { componentId } = action.payload;
            if (
                state.editorComponentsErrors[componentId] &&
                state.editorComponentsErrors[componentId].length
            ) {
                state.editorComponentsErrors[componentId] = [];
            }
        },
        toggleComponentVisibility: (
            state,
            action: PayloadAction<{ componentId: string }>
        ) => {
            const { payload } = action;
            if (!state.hiddenComponents) {
                state.hiddenComponents = [payload.componentId];
            } else if (
                state.hiddenComponents.indexOf(payload.componentId) > -1
            ) {
                state.hiddenComponents = state.hiddenComponents.filter(
                    (id) => id !== payload.componentId
                );
            } else {
                state.hiddenComponents.push(payload.componentId);
            }
        },
    },
});

export const {
    selectComponent,
    performZoom,
    setEditingTemplate,
    setTemplateId,
    resetEditorState,
    setEditorComponentProps,
    setEditorComponentError,
    clearEditorComponentErrors,
    toggleComponentVisibility,
} = editorSlice.actions;
export default editorSlice;
