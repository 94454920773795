import { configureStore } from '@reduxjs/toolkit';
import { save, load } from './middleware/storage';
import { Reducer } from 'redux';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { LOCAL_STORAGE_NS } from '../constants';
import structureSlice from './features/structure';
import exampleDataSlice from './features/exampleData';
import documentSlice from './features/document';
import editorSlice from './features/editor';
import { undox, UndoxState, UndoxTypes } from 'undox';
import { Component } from 'jsx-engine';

const localStorageMiddlewareConfig = {
    namespace: LOCAL_STORAGE_NS,
    debounce: 500,
};

export const store = configureStore({
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat([save(localStorageMiddlewareConfig)]),
    preloadedState: load(localStorageMiddlewareConfig),
    reducer: {
        structure: undox(structureSlice.reducer) as Reducer<
            UndoxState<Component, any>
        >,
        exampleData: exampleDataSlice.reducer,
        document: documentSlice.reducer,
        editor: editorSlice.reducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const undoAction = () => store.dispatch({ type: UndoxTypes.UNDO });
export const redoAction = () => store.dispatch({ type: UndoxTypes.REDO });
export const clearHistoryAction = () => {
    // TODO: not supported by lib right now :(
};
