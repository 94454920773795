import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

const exampleDataSlice = createSlice({
    name: 'exampleData',
    initialState: {},
    reducers: {
        updateExampleData: (state, action: PayloadAction<string>) => {
            try {
                return JSON.parse(action.payload);
            } catch {
                return state;
            }
        },
    },
});

export const { updateExampleData } = exampleDataSlice.actions;
export default exampleDataSlice;
