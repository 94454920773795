import {
    Toolbar,
    AppBar as MuiAppBar,
    makeStyles,
    Typography,
} from '@material-ui/core';
import { AppBarProfile } from './AppBarProfile';
import logo from './logo.svg';

const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    logoWrapper: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 900,
    },
    logo: {
        height: 30,
        marginRight: 30,
    },
});

export function AppBar() {
    const styles = useStyles();
    return (
        <MuiAppBar position={'static'}>
            <Toolbar variant={'dense'} className={styles.toolbar}>
                <Typography variant={'button'} className={styles.logoWrapper}>
                    <img
                        src={logo}
                        className={styles.logo}
                        alt={'Berrylium logo'}
                    />
                    Templates Editor
                </Typography>
                <AppBarProfile />
            </Toolbar>
        </MuiAppBar>
    );
}
